import { getCurrentInstance } from "vue";
import {
  HubReturnType,
  UniqueHubReturnType,
  useSignalR,
} from "../../signalr.client";
import { registerEventBusHandler } from "../../utils/eventBus";
import {
  MensaHubEvents,
  MenuplannerHubEvents,
  MenuPlanReplaceHubEvents,
} from "./signalR.events";
import { SignalRHub } from "../../hubs";

export const useMenuPlannerHub = (
  signalRConnectionId: string,
  connectionHubEndpoint: string,
  context: ReturnType<typeof getCurrentInstance>
): UniqueHubReturnType<typeof MenuplannerHubEvents> => {
  const signalRConnectionUrl = new URL(
    `${connectionHubEndpoint}/hubs/${SignalRHub.MENUPLANNER}`
  );
  signalRConnectionUrl.searchParams.set(
    "signalRConnectionId",
    signalRConnectionId
  );

  const signalR: ReturnType<typeof useSignalR> = useSignalR(
    decodeURIComponent(signalRConnectionUrl.toString()),
    context
  );
  const events = MenuplannerHubEvents;

  for (const eventName of Object.values(events)) {
    // Register all available events and publish eventBus action upon receiving any message on the signalR channel.
    registerEventBusHandler<typeof MenuplannerHubEvents>(signalR, eventName);
  }

  return {
    signalRConnectionId,
    signalR,
    events,
  };
};

export const useMenuPlanReplaceHub = (
  guid: string,
  connectionHubEndpoint: string,
  context: ReturnType<typeof getCurrentInstance>
): HubReturnType<typeof MenuPlanReplaceHubEvents> => {
  const signalRConnectionUrl = new URL(
    `${connectionHubEndpoint}/hubs/${SignalRHub.MENU_PLAN__REPLACE}`
  );
  signalRConnectionUrl.searchParams.set("replaceGuid", guid);

  const signalR: ReturnType<typeof useSignalR> = useSignalR(
    signalRConnectionUrl.toString(),
    context
  );
  const events = MenuPlanReplaceHubEvents;

  for (const eventName of Object.values(events)) {
    // Register all available events and publish eventBus action upon receiving any message on the signalR channel.
    registerEventBusHandler<typeof MenuPlanReplaceHubEvents>(
      signalR,
      eventName
    );
  }

  return {
    signalR,
    events,
  };
};

export const useMensaExportHub = (
  signalRConnectionId: string,
  connectionHubEndpoint: string,
  context: ReturnType<typeof getCurrentInstance>
): UniqueHubReturnType<typeof MensaHubEvents> => {
  const signalRConnectionUrl = new URL(
    `${connectionHubEndpoint}/hubs/${SignalRHub.MENSA__EXPORT}`
  );
  signalRConnectionUrl.searchParams.set(
    "signalRConnectionId",
    signalRConnectionId
  );

  const signalR: ReturnType<typeof useSignalR> = useSignalR(
    signalRConnectionUrl.toString(),
    context
  );
  const events = MensaHubEvents;

  for (const eventName of Object.values(events)) {
    // Register all available events and publish eventBus action upon receiving any message on the signalR channel.
    registerEventBusHandler<typeof MensaHubEvents>(signalR, eventName);
  }

  return {
    signalRConnectionId,
    signalR,
    events,
  };
};
