import { HubReturnType } from "../signalr.client";

export const registerEventBusHandler = <T>(
  signalR: HubReturnType<T>["signalR"],
  eventName: string
) => {
  signalR.on(eventName, (data) => {
    signalR.eventBus.instance.publish({
      type: "SignalRMessageReceived",
      payload: {
        message: eventName,
        data,
      },
    });
  });
};
