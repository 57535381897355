import { watch } from "vue";
import {
  deepMapLoadedTranslationJson,
  useLanguageCode,
} from "../utils/LocalizationHelper";
import { useLocize } from "./locize";
import { RootTranslationObject } from "./types";

type TranslationEngineConfig = {
  languageCode: string;
  fallbackLanguageCode?: string;
  namespace: string;
};

export const useTranslationEngine = (
  i18n,
  localeContext,
  config: TranslationEngineConfig
) => {
  const loadLocizeMessages = async (): Promise<RootTranslationObject> => {
    const { languageCode } = useLanguageCode(null);

    languageCode.value = languageCode.value || config.languageCode;

    const { getTranslation, mergeTranslationWithCache, onTranslationsFetched } =
      useLocize(
        config.namespace,
        languageCode.value,
        config.fallbackLanguageCode
      );

    // in case apps build with webpack use require.context, which returns an object with a key function.
    // apps build with Vite just return a key value object.
    const localTranslations =
      (localeContext.keys
        ? getLocalTranslations(localeContext)
        : getLocalTranslationsVite(localeContext)) || {};

    const setLocaleMessages = async (
      namespace: string,
      languageCode: string,
      translation: RootTranslationObject
    ) => {
      const translations = await mergeTranslationWithCache(
        namespace,
        languageCode,
        translation
      );

      if (!Object.values(translations)?.length) {
        return;
      }

      i18n.global.setLocaleMessage(languageCode, translations);
    };

    /* This will be triggered whenever we fetch new translations from our cache. */
    onTranslationsFetched(async (event, newTranslationData) => {
      if (!newTranslationData || !(newTranslationData instanceof Map)) {
        return;
      }

      const mapArray = Array.from(newTranslationData);

      for (const [languageCode, translation] of mapArray) {
        await setLocaleMessages(config.namespace, languageCode, translation);
      }
    });

    await getTranslation();

    for (const languageCode in localTranslations) {
      const languageSpecificLocalTranslation = (localTranslations[
        languageCode
      ] || {}) as RootTranslationObject;

      const translations = await mergeTranslationWithCache(
        config.namespace,
        languageCode,
        languageSpecificLocalTranslation
      );

      await setLocaleMessages(config.namespace, languageCode, translations);
    }

    i18n.global.locale.value = languageCode.value;

    watch(languageCode, (newLanguageCode) => {
      i18n.global.locale.value = newLanguageCode;
    });

    return localTranslations;
  };

  return {
    loadLocizeMessages,
  };
};

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function getLocalTranslations(localesContext) {
  const messages: Record<string, RootTranslationObject> = {};

  localesContext.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i); // example matched variable content: ['de-DE.', 'de-DE]

    if (matched && matched.length > 1) {
      const locale = matched[1];

      /* When locales(key).default reads a json file, it's deep object properties are actually functions instead of strings.  */
      messages[locale] = deepMapLoadedTranslationJson(
        localesContext(key).default
      );
    }
  });

  return messages;
}

function getLocalTranslationsVite(
  translationModules: Record<string, RootTranslationObject>[]
) {
  const messages: Record<string, RootTranslationObject> = {};

  for (const path in translationModules) {
    const matched = path.match(/\/([^/]+)\.json$/i); // example:  ['de-DE.', 'de-DE]

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = translationModules[path];
    }
  }

  return messages;
}
