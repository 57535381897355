import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { getCurrentInstance, onBeforeUnmount, ref } from "vue";
import { useEventBus } from "../utils/eventBus";

export type SignalRComposableOptions = {
  retryAfter?: number[]
};

export type SignalREventBusPayload = {
  message: string;
  data: unknown;
};

const _DEFAULT_OPTIONS: SignalRComposableOptions = {
  retryAfter: [1000, 2000, 5000, 10000, 15000, 20000, 25000, 30000]
};
const connections = new Map<string, HubConnection>();

export const useSignalR = (
  connectionUrl: string,
  context: ReturnType<typeof getCurrentInstance>,
  _options?: SignalRComposableOptions
) => {
  const connection = ref<HubConnection>();
  const options = { ..._DEFAULT_OPTIONS, ..._options };
  const listeners = ref(new Map<string, Function[]>());
  const eventBus = useEventBus<SignalREventBusPayload>(connectionUrl, context);

  if (connections.has(connectionUrl)) {
    connection.value = connections.get(connectionUrl);
  } else {
    connection.value = new HubConnectionBuilder()
      .withUrl(connectionUrl)
      .withAutomaticReconnect(options.retryAfter)
      .configureLogging(LogLevel.Debug)
      .withHubProtocol(new JsonHubProtocol())
      .build();
    connections.set(connectionUrl, connection.value);
  }

  let connectionStartedPromise;
  const start = () => {
    if (
      // We are already connected to the hub, no need to start another connection.
      ![
        HubConnectionState.Disconnecting,
        HubConnectionState.Disconnected,
      ].includes(connection.value.state)
    ) {
      return;
    }

    connectionStartedPromise = connection.value.start().catch((error) => {
      console.error(
        `SignalR connection failed on hub: ${connectionUrl}`,
        error
      );

      return new Promise((resolve, reject) => {
        // Try reconnecting if the initial attempt failed
        setTimeout(
          () => start().then(resolve).catch(reject),
          5000
        );
      });
    });

    return connectionStartedPromise;
  };

  const on: typeof connection.value.on = (methodName, newMethod) => {
    listeners.value.set(methodName, [
      ...(listeners.value.get(methodName) || []),
      newMethod,
    ]);
    connection.value.on(methodName, newMethod);
  };
  const offAll: typeof connection.value.off = (methodName) => {
    listeners.value.delete(methodName);
    connection.value.off(methodName);
  };
  const off: (methodName: string, method: (...args: any[]) => void) => void = (
    methodName,
    method
  ) => {
    listeners.value.set(
      methodName,
      (listeners.value.get(methodName) || []).filter(
        (method_) => method_ !== method
      )
    );
    connection.value.off(methodName, method);
  };
  const onDisconnected = (callback: (...args: unknown[]) => void) => {
    listeners.value.set("disconnected", [
      ...(listeners.value.get("disconnected") || []),
      callback,
    ]);
    connection.value.on("disconnected", callback);
  };
  const onReconnecting = (callback: (...args: unknown[]) => void) => {
    connection.value.onreconnecting(callback);
  };
  const onReconnected = (callback: (...args: unknown[]) => void) => {
    connection.value.onreconnected(callback);
  };

  const onClosed = (callback: (...args: unknown[]) => void) => {
    connection.value.onclose((...args) => {
      callback(...args);
      start();
    });
  };

  start();

  onBeforeUnmount(() => {
    listeners.value.forEach((methods, methodName) => {
      offAll(methodName);
    });
  }, context);

  return {
    connection,
    eventBus,
    on,
    off,
    offAll,
    onDisconnected,
    onReconnecting,
    onReconnected,
    onClosed,
  };
};

export type HubReturnType<T> = {
  signalR: ReturnType<typeof useSignalR>;
  events: T;
};

export type UniqueHubReturnType<T> = HubReturnType<T> & {
  signalRConnectionId: string;
};

export * from "./modules/menuplanner-dashboard";
export * from "./modules/basket";
export * from "./modules/menuselection";
