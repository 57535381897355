import { getEventBusInstance, useEventBus } from "./eventBus";
import {
  ComponentInternalInstance,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";

export const DEFAULT_NAVIGATION_EVENT = "navigation:set-application-route";
export const DEFAULT_NAVIGATION_ITEM_CLICKED =
  "navigation:set-application-on-click";

export type NavigationRouteData = {
  applicationName: string;
  pageTitle?: string;
};

export const dispatchNavigationData = (
  applicationName: string,
  pageTitle?: string,
  namespace?: string
) => {
  const eventBus = getEventBusInstance<NavigationRouteData>(
    namespace || DEFAULT_NAVIGATION_EVENT
  );

  eventBus.dispatch({
    type: "navigation:set-application-route",
    payload: {
      applicationName,
      pageTitle,
    },
  });
};

export const useNavigationData = (
  context: ComponentInternalInstance | null,
  onUpdate?: (payload: NavigationRouteData | null) => void,
  namespace = DEFAULT_NAVIGATION_EVENT
) => {
  const eventBus = useEventBus<NavigationRouteData>(namespace, context);
  const data = reactive({
    currentApplicationName: "",
    pageTitle: "",
  });
  // MutationObserver for detecting URI changes (path, hash, query params, etc)
  const currentUri = ref(window.location.href);
  const observer = new MutationObserver(() => {
    const uri = window.location.href;

    if (uri !== currentUri.value) {
      onUpdate(null);
      currentUri.value = uri;
    }
  });

  const setNavigationData = (payload: NavigationRouteData) => {
    data.currentApplicationName = payload.applicationName;
    data.pageTitle = payload.pageTitle;

    typeof onUpdate === "function" && onUpdate(payload);
  };

  const destroySubscription = eventBus.subscribeEventBusAction((event) => {
    setNavigationData(event.payload);
  });

  onBeforeMount(() => {
    eventBus.getEventBusPayload().then((payload) => {
      setNavigationData(payload);
    });

    // The best working solution for detecting URI changes (path, hash, query params, etc)
    observer.observe(document, {
      subtree: true,
      childList: true,
    });
  }, context);

  onBeforeUnmount(() => {
    destroySubscription();
    observer?.disconnect();
  }, context);

  return data;
};

export type EventTargetWithHref = {
  currentTarget: EventTarget & { href: string };
};

export type NavigateToUrlArgument =
  | string
  | (Omit<Event, "currentTarget"> & EventTargetWithHref);

export const getURIAnchorElement = (uri: string) => {
  const anchor = document.createElement("a");
  anchor.href = uri;
  return anchor;
};

export const getCurrentURIFullPath = () => {
  const { pathname, hash } = window.location;

  if (pathname.length === 1) {
    return null;
  }

  const hashPath = hash.replace(/^#/, "");

  if (hashPath.length === 1) {
    // Hash points to root, there is no need to append it to pathname
    return pathname;
  }

  return `${pathname}${hash}`;
};

export const navigateToUrl = (obj: NavigateToUrlArgument) => {
  let url: string;

  if (typeof obj === "string") {
    url = obj;
  } else if (
    obj &&
    obj.currentTarget &&
    obj.currentTarget.href &&
    obj.preventDefault
  ) {
    url = obj.currentTarget.href;
    obj.preventDefault();
  } else {
    return;
  }

  const current = getURIAnchorElement(window.location.href);
  const destination = getURIAnchorElement(url);

  if (url.indexOf("#") === 0) {
    window.location.hash = destination.hash;
  } else if (current.host !== destination.host && destination.host) {
    window.location.href = url;
  } else {
    // different path, host, or query params
    window.history.pushState({}, "", url);
  }
};

export default {
  dispatchNavigationData,
  useNavigationData,
  navigateToUrl,
  getURIAnchorElement,
  getCurrentURIFullPath,
};
