export const ARTICLE_EVENTS = {
  CREATE_ARTICLE: "CREATE_ARTICLE",
  CREATE_ARTICLE_SUCCESS: "CREATE_ARTICLE_SUCCESS",
  EDIT_ARTICLE: "EDIT_ARTICLE",
  EDIT_ARTICLE_SUCCESS: "EDIT_ARTICLE_SUCCESS",
  DELETE_ARTICLE: "DELETE_ARTICLE",
  DELETE_ARTICLE_SUCCESS: "DELETE_ARTICLE_SUCCESS",
  COPY_ARTICLE: "COPY_ARTICLE",
  COPY_ARTICLE_SUCCESS: "COPY_ARTICLE_SUCCESS",
} as const;

export type ArticleEvents = typeof ARTICLE_EVENTS[keyof typeof ARTICLE_EVENTS];
