export enum MenuplannerHubEvents {
  COPY__FAILED = "MenuPlanCopyFailed",
  STATUS__CHANGED = "MenuPlanStatusChanged",
  COPY__SUCCESSFUL = "MenuPlanCopySuccessfully",
}

export enum MenuPlanReplaceHubEvents {
  SEND_UPDATE = "SendReplaceArticleUpdate",
}

export enum MensaHubEvents {
  SPLIT__COMPLETED = "SplitPlanCompleted",
  EXPORT_WEEK__SUCCESS = "ExportWeekSuccess",
  EXPORT_WEEK__FAILED = "ExportWeekFailed",
  EXPORT__COMPLETED = "ExportCompleted",
}
