import { ICurrentUserData } from "../models/userData.interface";
import { authentication } from "./index";
import { getEventBusInstance } from "../utils/eventBus";

export const onCorrectLanguageCodeLoad = (
  callback: (userData?: ICurrentUserData) => void
) => {
  const isAuthenticated = authentication.isSignedIn();

  if (isAuthenticated) {
    const userDataEventBus = getEventBusInstance<ICurrentUserData>(
      "@apetito/sspa-user-data"
    );
    const { payload } = userDataEventBus.getLastEvent() || {};

    if (payload) {
      return callback(payload);
    }

    return userDataEventBus.subscribe((userDataEvent) => {
      const { payload } = userDataEvent || {};

      if (payload) {
        return callback(payload);
      }
    });
  }

  return callback();
};

export const waitForAuthentication = () => {
  return new Promise((resolve) => {
    onCorrectLanguageCodeLoad((userData) => {
      resolve(userData);
    });
  });
};
