import { Action } from "../models/action";
import { getEventBusInstance } from "../utils/eventBus";

export const CUSTOMER_CHANGE_REQUEST_NAMESPACE =
  "@apetito/customer-change-request";

export type CustomerChangeRequestAction = {
  customerNumber: number;
  successCallback: () => void;
  errorCallback: () => void;
};

export function requestCustomerNumberChange(
  customerNumber: number,
  successCallback: () => void,
  errorCallback: () => void
) {
  const eventBus = getEventBusInstance<CustomerChangeRequestAction>(
    CUSTOMER_CHANGE_REQUEST_NAMESPACE
  );

  const action: Action<CustomerChangeRequestAction> = {
    type: "customer-change-request",
    payload: { customerNumber, successCallback, errorCallback },
  };

  eventBus.dispatch(action);
}
