/* eslint-disable no-console */
import {
  NamespaceTranslationDataResponse,
  RootTranslationObject,
} from "./types";
import { deepMergeTranslations, parseNamespaceValue } from "./helpers";
import { useEventBus } from "@vueuse/core";

type LanguageCode = string;
type TranslationNamespace = string;
type LanguageTranslationsMap = Map<LanguageCode, RootTranslationObject>;
type LocizeTranslationCacheMap = Map<
  TranslationNamespace,
  LanguageTranslationsMap
>;

export const LOCIZE__TRANSLATIONS_CACHE: LocizeTranslationCacheMap = new Map();
const LOCIZE__PROMISE_SET: Set<`${TranslationNamespace}.${LanguageCode}`> =
  new Set();

// @ts-ignore
window.LOCIZE__PROMISE_SET = LOCIZE__PROMISE_SET;

const initCache = (
  languageCode: LanguageCode | LanguageCode[],
  namespace: TranslationNamespace
) => {
  const languageCodes = (
    Array.isArray(languageCode) ? languageCode : [languageCode]
  ).filter(Boolean);

  languageCodes.forEach((languageCode) => {
    if (!LOCIZE__TRANSLATIONS_CACHE.has(namespace)) {
      const namespaceTranslationMap: LanguageTranslationsMap = new Map();
      LOCIZE__TRANSLATIONS_CACHE.set(namespace, namespaceTranslationMap);
    }

    const namespaceTranslationMap = LOCIZE__TRANSLATIONS_CACHE.get(namespace);

    if (!namespaceTranslationMap.has(languageCode)) {
      namespaceTranslationMap.set(languageCode, {});
    }
  });
};

const getNamespaceTranslationsFromCache = (namespace: TranslationNamespace) => {
  return LOCIZE__TRANSLATIONS_CACHE.get(namespace);
};

const getNamespacesTranslationsFromCache = (
  namespaces: TranslationNamespace[]
) => {
  return namespaces.reduce((acc, namespace) => {
    acc.set(namespace, getNamespaceTranslationsFromCache(namespace));

    return acc;
  }, new Map() as LocizeTranslationCacheMap);
};

const getNamespaceTranslationFromCache = (
  namespace: TranslationNamespace,
  languageCode: LanguageCode
) => {
  initCache(languageCode, namespace);
  const translations = getNamespaceTranslationsFromCache(namespace);

  return translations.get(languageCode);
};

const hasTranslation = (
  namespace: TranslationNamespace,
  languageCode: LanguageCode
) => {
  const translation = getNamespaceTranslationFromCache(namespace, languageCode);

  return translation && Object.keys(translation).length > 0;
};

const getEmptyTranslationsNamespaces = (namespaces: TranslationNamespace[]) => {
  const translationsMap = getNamespacesTranslationsFromCache(namespaces);

  return Array.from(translationsMap)
    .filter(
      ([_namespace, translation]) =>
        !translation || !Object.keys(translation).length
    )
    .map(([namespace]) => namespace);
};

const cacheNamespaceTranslation = async (
  namespace: TranslationNamespace,
  languageCode: LanguageCode,
  translation: RootTranslationObject,
  analyze?: boolean
) => {
  initCache(languageCode, namespace);

  const namespaceTranslations = getNamespaceTranslationsFromCache(namespace);
  const currentTranslation = namespaceTranslations.get(languageCode);

  const mergedTranslation = await deepMergeTranslations(
    namespace,
    currentTranslation,
    translation,
    analyze
  );

  namespaceTranslations.set(languageCode, mergedTranslation);

  return {
    languageCode,
    translation: mergedTranslation,
  };
};

export const useLocizeCache = (
  namespace: string,
  languageCode: string,
  fallbackLanguageCode?: string
) => {
  const localEventBus = useEventBus<string, LanguageTranslationsMap>(
    `TranslationsFetchEventBus:${namespace}`
  );
  const onTranslationsFetched = localEventBus.on;

  const fetchCachedTranslations = (
    namespaces: string | string[],
    languageCode: string
  ): Promise<LocizeTranslationCacheMap> => {
    if (!Array.isArray(namespaces)) {
      namespaces = [namespaces];
    }

    const parsedNamespaces = namespaces
      .map((namespace) => parseNamespaceValue(namespace))
      .filter((namespace) => {
        const hasMainTranslation = hasTranslation(namespace, languageCode);
        const hasFallbackTranslation = hasTranslation(
          namespace,
          fallbackLanguageCode
        );

        const hasPromise = LOCIZE__PROMISE_SET.has(
          `${namespace}.${languageCode}`
        );

        return (!hasMainTranslation || !hasFallbackTranslation) && !hasPromise;
      });

    // The translations for all of passed namespaces already exists in the cache, there's no need to fetch it again.
    if (!parsedNamespaces.length && namespaces.length) {
      return Promise.resolve(LOCIZE__TRANSLATIONS_CACHE);
    }

    const params = new URLSearchParams(
      parsedNamespaces.map((namespace) => {
        LOCIZE__PROMISE_SET.add(`${namespace}.${languageCode}`);

        return ["namespaces[]", namespace];
      })
    );

    if (languageCode === fallbackLanguageCode) {
      params.set("isFallback", "1");
    }

    if (fallbackLanguageCode && fallbackLanguageCode !== languageCode) {
      params.set("fallbackLanguageCode", fallbackLanguageCode);
    }

    const uri = `/api/locize/${languageCode}?${params.toString()}`;

    return new Promise((resolve, reject) => {
      fetch(uri)
        .then((result) => result.json())
        .then((result: NamespaceTranslationDataResponse[]) => {
          result.forEach(async (languageTranslationData) => {
            const { locizeDtos, languageCode: translationLanguageCode } =
              languageTranslationData;

            const newTranslations: LanguageTranslationsMap = new Map();

            for (const locizeDto of locizeDtos) {
              const {
                namespaceName: namespace,
                translations: translationsString,
              } = locizeDto;

              try {
                const translations = JSON.parse(translationsString);

                const cachedTranslation = await cacheNamespaceTranslation(
                  namespace,
                  translationLanguageCode || languageCode,
                  translations
                );

                newTranslations.set(
                  translationLanguageCode || languageCode,
                  cachedTranslation.translation
                );
              } catch (error) {
                console.error(error);
              }

              LOCIZE__PROMISE_SET.delete(`${namespace}.${languageCode}`);
            }

            localEventBus.emit("TranslationsFetchEvent", newTranslations);
          });

          resolve(LOCIZE__TRANSLATIONS_CACHE);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          parsedNamespaces.forEach((namespace) => {
            LOCIZE__PROMISE_SET.delete(`${namespace}.${languageCode}`);
          });
        });
    });
  };

  /**
   * @return Translations object for a single namespace
   * */
  const getNamespaceTranslations = async (
    namespace: TranslationNamespace,
    languageCode: LanguageCode
  ): Promise<RootTranslationObject> => {
    namespace = parseNamespaceValue(namespace);

    initCache([languageCode, fallbackLanguageCode], namespace);

    if (!hasTranslation(namespace, languageCode)) {
      await fetchCachedTranslations(namespace, languageCode);
    }

    return getNamespaceTranslationFromCache(namespace, languageCode);
  };

  /**
   * @return Map of namespaces and their translations objects
   * */
  const getNamespacesTranslations = async (
    namespaces: TranslationNamespace[]
  ): Promise<LocizeTranslationCacheMap> => {
    namespaces = namespaces.map((namespace) => parseNamespaceValue(namespace));
    let emptyNamespaces = getEmptyTranslationsNamespaces(namespaces);

    if (emptyNamespaces.length) {
      await fetchCachedTranslations(emptyNamespaces, languageCode);
    }

    return getNamespacesTranslationsFromCache(namespaces);
  };

  const mergeTranslationWithCache = async (
    namespace: string,
    languageCode: LanguageCode,
    partialTranslation: RootTranslationObject
  ) => {
    namespace = parseNamespaceValue(namespace);
    const currentTranslation = getNamespaceTranslationFromCache(
      namespace,
      languageCode
    );

    return await deepMergeTranslations(
      namespace,
      currentTranslation,
      partialTranslation,
      true
    );
  };

  return {
    onTranslationsFetched,
    getNamespaceTranslations,
    getNamespacesTranslations,
    mergeTranslationWithCache,
  };
};

export const useLocize = (
  namespace: string,
  languageCode: string,
  fallbackLanguageCode: string
) => {
  namespace = parseNamespaceValue(namespace);
  const cache = useLocizeCache(namespace, languageCode, fallbackLanguageCode);

  const getTranslation = async (): Promise<RootTranslationObject> => {
    const hasDefaultTranslation = hasTranslation(namespace, languageCode);
    const hasFallbackTranslation = hasTranslation(
      namespace,
      fallbackLanguageCode
    );

    if (hasDefaultTranslation) {
      return getNamespaceTranslationFromCache(namespace, languageCode);
    } else {
      /* Translation has not yet been fetched - let's get it from the cache API. */
      const { getNamespaceTranslations } = useLocizeCache(
        namespace,
        languageCode,
        fallbackLanguageCode
      );

      await getNamespaceTranslations(namespace, languageCode);
    }

    if (!hasTranslation(namespace, fallbackLanguageCode)) {
      return getNamespaceTranslationFromCache(namespace, fallbackLanguageCode);
    }

    /* Translation has not yet been fetched - let's get it from the cache API. */
    const { getNamespaceTranslations } = useLocizeCache(
      namespace,
      languageCode,
      fallbackLanguageCode
    );

    return await getNamespaceTranslations(namespace, languageCode);
  };

  return {
    ...cache,
    getTranslation,
  };
};

export { useTranslationEngine } from "./i18n";
