import { RootTranslationObject } from "../locize/types";
import { getCurrentInstance, onBeforeUnmount, ref } from "vue";
import { useEventBus } from "./eventBus";
import { ICurrentUserData } from "../models/userData.interface";

export const getImprintUrl = () => {
  const imprintUrls = {
    "de-DE": "https://www.apetito.de/impressum",
    "de-AT": "https://www.apetito.co.at/impressum",
    "nl-NL": "https://www.apetito.nl/service/algemene-voorwaarden.html",
  };
  const fallBackUrl = "https://www.apetito.de/impressum";
  const domain = getLanguageCodeFromUrl();

  return imprintUrls[domain] ?? fallBackUrl;
};

export const getLanguageCodeFromUrl = () => {
  const topLevelDomain = window.location.hostname.match(".([^\\.]+?)$");
  const languageCodes = {
    de: "de-DE",
    at: "de-AT",
    nl: "nl-NL",
  };

  if (!topLevelDomain || topLevelDomain.length < 2) {
    return languageCodes.de;
  }

  return languageCodes[topLevelDomain[1]] ?? languageCodes.de;
};

/**
 * @returns The prioritized language code from a collection of language codes
 * */
export function usePrioritizedLanguageCode(
  languageCodes?: string[] | undefined
): string {
  let languageCode = "de-DE";
  const urlLanguageCode = getLanguageCodeFromUrl();

  if (urlLanguageCode?.length) {
    languageCode = urlLanguageCode;
  }

  if (languageCodes?.length) {
    // send language code of the single selected customer number OR just de-DE as default
    return languageCodes[0] ?? languageCode;
  }

  // if multiple language codes are passed, return the prioritized one
  if (languageCodes?.includes("de-DE")) {
    languageCode = "de-DE";
  } else if (languageCodes?.includes("de-AT")) {
    languageCode = "de-AT";
  } else if (languageCodes?.includes("nl-NL")) {
    languageCode = "nl-NL";
  }

  return languageCode;
}

export const useLanguageCode = (
  context: ReturnType<typeof getCurrentInstance>,
  onLanguageChange?: (newLanguageCode: string) => void
) => {
  const eventBus = useEventBus<ICurrentUserData>(
    "@apetito/sspa-user-data",
    context
  );
  const languageCode = ref(usePrioritizedLanguageCode());
  const setLanguageCodeFromEventPayload = (payload: ICurrentUserData) => {
    const languageCodes = payload?.all?.languageCodes || [];
    languageCode.value = usePrioritizedLanguageCode(languageCodes);

    if (typeof onLanguageChange === "function") {
      onLanguageChange(languageCode.value);
    }
  };
  const { payload } = eventBus.instance.getLastEvent() || {};

  setLanguageCodeFromEventPayload(payload);

  eventBus
    .getEventBusPayload()
    .then((payload) => setLanguageCodeFromEventPayload(payload));

  const unsubscribeHandler = eventBus.subscribeEventBusAction((event) => {
    const { payload } = event;

    if (event.type === "CustomerChanged") {
      setLanguageCodeFromEventPayload(payload);
    }
  });

  onBeforeUnmount(() => {
    unsubscribeHandler && unsubscribeHandler();
  }, context);

  return {
    languageCode,
  };
};

export const deepMapLoadedTranslationJson = (
  translation: RootTranslationObject | string
) => {
  if (typeof translation === "string") {
    try {
      translation = JSON.parse(translation) as RootTranslationObject;
    } catch (error) {
      translation = {};
      console.error(error);
    }
  }

  const translationClone = { ...translation };

  Object.keys(translationClone).forEach((key) => {
    const value = translationClone[key];

    /* If the value is an object, we need to walk through it recursively to map all it's values as strings. */
    if (typeof value === "object") {
      translationClone[key] = deepMapLoadedTranslationJson(value);
      return;
    }

    /**
     * "@intlify/vue-i18n-loader" is mapping json files in such a way it transforms it's nested string values to functions.
     * Resulting function has a `source` field on it containing raw value of the string which we can use here.
     *
     * @link https://github.com/intlify/bundle-tools/tree/main/packages/vue-i18n-loader#forcestringify
     * */
    const valueIsFunction = !!(value && typeof value === "function");
    const { source } = ((valueIsFunction && value) ||
      Function) as FunctionConstructor & {
      source?: string;
    };

    /* If the "fn.source" is provided, we set it as a value for current key. We leave the value untouched otherwise. */
    if (source) {
      translationClone[key] = source;
    }
  });

  return translationClone;
};
