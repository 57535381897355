import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
} from "@microsoft/applicationinsights-web";
import env from "../utils/env";

interface ServiceInfo {
  name: string;
  consent: {
    status: boolean;
  };
}

declare global {
  // define user centrics object (only needed subset) on window as it is defined, when the library is loaded.
  interface Window {
    UC_UI: {
      isInitialized: () => boolean;
      getServicesBaseInfo: () => ServiceInfo[];
    };
  }
}

export type IAppInsightsUserData = {
  orderSystem: string;
  customerNumber: number;
};

const appInsightsConnectionString =
  env.PORTAL_SDK_COMMON_APP_INSIGHTS_CONNECTIONSTRING;

const appInsightsOriginal = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    enableAutoRouteTracking: true,
  },
});

let appInsightsLoaded = false;

function isAppInsightsAccepted() {
  if (window.UC_UI && window.UC_UI.isInitialized()) {
    // id must match the ID from app insights management panel
    const appInsightsName = "Azure Application Insights";
    const appInsightsAccepted = window.UC_UI.getServicesBaseInfo()
      .filter((data) => data.consent.status === true)
      .find((info) => info.name === appInsightsName);

    return !!appInsightsAccepted;
  }
  return false;
}

function onUserCentricsLibraryDefined() {
  if (!isAppInsightsAccepted()) {
    return;
  }

  if (!appInsightsLoaded) {
    appInsightsOriginal.loadAppInsights();
    appInsightsLoaded = true;
  }
}

if (window.UC_UI) {
  onUserCentricsLibraryDefined();
} else {
  // wait for user centrics to be defined (is set by the user centrics lib included in index.ejs) and then check app insights consent.
  Object.defineProperty(window, "UC_UI", {
    configurable: true,
    enumerable: true,
    get: function () {
      return this._uc_ui;
    },
    set: function (val) {
      this._uc_ui = val;
      onUserCentricsLibraryDefined();
    },
  });
}

// proxy which forwards the calls to the original appInsights, when it's accepted by the user.
export const appInsights = {
  trackEvent: (
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ) => {
    if (isAppInsightsAccepted()) {
      appInsightsOriginal.trackEvent(event, customProperties);
    }
  },
};

export function setAppInsightsUser(userData: IAppInsightsUserData) {
  if (isAppInsightsAccepted()) {
    appInsightsOriginal.config.accountId = userData.customerNumber.toString();
    appInsightsOriginal.addTelemetryInitializer((envelope) => {
      envelope.tags["ai.cloud.role"] = userData.orderSystem;
      envelope.tags["ai.cloud.roleInstance"] =
        userData.customerNumber.toString();
    });
    appInsightsOriginal.trackPageView();
  }
}

export default {
  appInsights,
  setAppInsightsUser,
};
