import { CustomerChangedActionPayload } from '../models/customer-changed-action'
import { NavigationRouteData } from '../utils/navigation'
enum GuardTypes {
  NO_PERMISSION,
  CUSTOMER_SELECTED,
}

const guardedApplications = [
  {
    name: '@apetito/menuservicemanager',
    neededOrderSystems: ['MSA', 'MSM'],
    guardTypes: [GuardTypes.NO_PERMISSION, GuardTypes.CUSTOMER_SELECTED],
  },
  {
    name: '@apetito/mylunch',
    neededOrderSystems: ['MYL'],
    guardTypes: [GuardTypes.NO_PERMISSION, GuardTypes.CUSTOMER_SELECTED],
  },
]

export const getGuards = (
  selectedCustomer: CustomerChangedActionPayload | undefined,
  selectedApplication: NavigationRouteData | null
) => {
  if (!selectedCustomer || !selectedApplication) return

  const activeApplicationGuards = guardedApplications.find(
    app => app.name === selectedApplication.applicationName
  )
  if (!activeApplicationGuards) return

  const effectiveOrderSystems = selectedCustomer.customerNumbers.flatMap(
    customer => customer.effectiveOrderSystems
  )

  return {
    CUSTOMER_SELECTED:
      (activeApplicationGuards.guardTypes.includes(GuardTypes.CUSTOMER_SELECTED) &&
        selectedCustomer.customerNumbers.length > 1) ??
      false,
    NO_PERMISSION:
      (activeApplicationGuards.guardTypes.includes(GuardTypes.NO_PERMISSION) &&
        !effectiveOrderSystems.some(system =>
          activeApplicationGuards.neededOrderSystems.includes(system)
        )) ??
      false,
  }
}
