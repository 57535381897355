import { getEventBusInstance } from "../utils/eventBus";
import singleSpaVue from "single-spa-vue";

export const APP_STATUS = {
  BOOTSTRAPPING: "BOOTSTRAPPING",
  MOUNTING: "MOUNTING",
  MOUNTED: "MOUNTED",
  NOT_MOUNTED: "NOT_MOUNTED",
  UPDATING: "UPDATING",
  UNMOUNTING: "UNMOUNTING",
} as const;

export type AppStatus = typeof APP_STATUS[keyof typeof APP_STATUS];
type SingleSPAProps = Record<string, unknown>;
export type AppStatusAction = {
  appName: string;
  status: AppStatus;
  singleSpaProps?: SingleSPAProps;
};

/**
 * @description This wrapping function is mainly used for extracting the ReturnType of getEventBusInstance generic function of AppStatusAction
 * */
const getAppLifecycleEventBus = (appNamespace: string) =>
  getEventBusInstance<AppStatusAction>(appNamespace);

export const getSingleSPALifecycleEventBus = () => {
  const eventBus = getAppLifecycleEventBus("@applications-lifecycles");

  return {
    eventBus,
  };
};

export const useAppLifecycleEventBus = (
  appName: string
): {
  appLifecycleEventBus: ReturnType<typeof getAppLifecycleEventBus>;
  sspaAppsEventBus: ReturnType<typeof getAppLifecycleEventBus>;
} => {
  const appLifecycleEventBus = getAppLifecycleEventBus(
    `@application-lifecycle:${appName}`
  );
  const { eventBus: sspaAppsEventBus } = getSingleSPALifecycleEventBus();

  return { appLifecycleEventBus, sspaAppsEventBus };
};

export const publishAppLifecycleHookStatus = (
  appName: string,
  status: AppStatus,
  singleSpaProps?: SingleSPAProps
): void => {
  const { appLifecycleEventBus, sspaAppsEventBus } =
    useAppLifecycleEventBus(appName);

  const payload = {
    appName,
    singleSpaProps,
    status,
  };

  // We always want to have only the last state of the application stored in the event bus cache.
  appLifecycleEventBus.clear();
  appLifecycleEventBus.publish({
    type: "APP_HOOK",
    payload,
  });

  // Let's publish the current state of the app over a general even bus.
  // It's main purpose is having a way to track if at least one application was mounted.
  sspaAppsEventBus.publish({
    type: "APP_LIFECYCLE__CHANGED",
    payload,
  });
};

type AppLifecycleHook = (singleSpaProps: SingleSPAProps) => Promise<void>;

export const useSingleSPALifecycleHooks = (
  appName: string,
  vueLifecycles: ReturnType<typeof singleSpaVue>
): {
  bootstrap: AppLifecycleHook;
  mount: AppLifecycleHook;
  unmount: AppLifecycleHook;
  update: AppLifecycleHook;
} => {
  const bootstrap: AppLifecycleHook = async (
    singleSpaProps: SingleSPAProps
  ) => {
    publishAppLifecycleHookStatus(appName, "BOOTSTRAPPING", singleSpaProps);

    return await vueLifecycles.bootstrap(singleSpaProps);
  };
  const mount: AppLifecycleHook = async (singleSpaProps: SingleSPAProps) => {
    publishAppLifecycleHookStatus(appName, "MOUNTING", singleSpaProps);
    const result = await vueLifecycles.mount(singleSpaProps);
    publishAppLifecycleHookStatus(appName, "MOUNTED", singleSpaProps);

    return result;
  };
  const unmount: AppLifecycleHook = async (singleSpaProps: SingleSPAProps) => {
    publishAppLifecycleHookStatus(appName, "UNMOUNTING", singleSpaProps);

    return await vueLifecycles.unmount(singleSpaProps);
  };
  const update: AppLifecycleHook = async (singleSpaProps: SingleSPAProps) => {
    publishAppLifecycleHookStatus(appName, "UPDATING", singleSpaProps);

    return await vueLifecycles.update(singleSpaProps);
  };

  return {
    bootstrap,
    mount,
    unmount,
    update,
  };
};

export default {
  APP_STATUS,
  publishAppLifecycleHookStatus,
  useAppLifecycleEventBus,
  useSingleSPALifecycleHooks,
};
