import { getCurrentInstance } from "vue";
import {
  BasketHubEvents,
  HubReturnType,
  useSignalR,
} from "../../signalr.client";
import { registerEventBusHandler } from "../../utils/eventBus";
import { SignalRHub } from "../../hubs";

export const useBasketHub = (
  shoppingSessionId: string,
  connectionHubEndpoint: string,
  context: ReturnType<typeof getCurrentInstance>
): HubReturnType<typeof BasketHubEvents> => {
  const signalRConnectionUrl = new URL(
    `${connectionHubEndpoint}/hubs/${SignalRHub.BASKET}`
  );
  signalRConnectionUrl.searchParams.set("shoppingSessionId", shoppingSessionId);

  const signalR: ReturnType<typeof useSignalR> = useSignalR(
    decodeURIComponent(signalRConnectionUrl.toString()),
    context
  );
  const events = BasketHubEvents;

  for (const eventName of Object.values(events)) {
    // Register all available events and publish eventBus action upon receiving any message on the signalR channel.
    registerEventBusHandler<typeof BasketHubEvents>(signalR, eventName);
  }

  return {
    signalR,
    events,
  };
};
