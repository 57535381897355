export function isObject(item: unknown) {
  return item && typeof item === "object" && !Array.isArray(item);
}

type DeepObject = { [key: string]: DeepObject | unknown };

export function deepMerge<T>(target: DeepObject, ...sources: DeepObject[]): T {
  if (!isObject(target)) {
    throw new Error("Target must be an object.");
  }

  let result = { ...target };

  for (const source of sources) {
    if (isObject(source)) {
      for (const [key, value] of Object.entries(source)) {
        const existingValue = result[key];

        if (isObject(value) && isObject(existingValue)) {
          // If both existing and new values are objects, merge them recursively
          result[key] = deepMerge(
            existingValue as DeepObject,
            value as DeepObject
          );
        } else {
          // If the new value is not an object or the target does not have an existing object,
          // simply assign the new value
          result[key] = value;
        }
      }
    }
  }

  return result as T;
}
