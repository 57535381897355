/* eslint-disable no-console */
import { RootTranslationObject } from "./types";
import { deepMerge } from "../utils/object";

export const parseNamespaceValue = (namespace: string) => {
  return namespace.replace(/^@apetito\//, "").toLowerCase();
};

export const flattenTranslations = (
  translations: RootTranslationObject,
  prefix = ""
): Record<string, string> => {
  return Object.keys(translations).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}.` : "";
    const value = translations[key] as RootTranslationObject;

    if (
      typeof value === "object" &&
      value !== null &&
      Object.keys(value).length > 0
    ) {
      return { ...acc, ...flattenTranslations(value, pre + key) };
    }

    acc[pre + key] = translations[key] as string;

    return acc;
  }, {} as Record<string, string>);
};

const analyzeTranslations = async (
  namespace: string,
  originalTranslations: RootTranslationObject,
  newTranslations: RootTranslationObject
) => {
  const flattenedOriginalTranslations =
    flattenTranslations(originalTranslations);
  const flattenedNewTranslations = flattenTranslations(newTranslations);
  const existingTranslations = [];

  for (const newTranslationKey in flattenedNewTranslations) {
    const existingTranslation =
      flattenedOriginalTranslations[newTranslationKey];
    const newTranslation = flattenedNewTranslations[newTranslationKey];

    if (existingTranslation && existingTranslation === newTranslation) {
      existingTranslations.push({
        translationKey: newTranslationKey,
        currentTranslation: existingTranslation,
        newTranslation,
      });
    }
  }
};

export const deepMergeTranslations = async (
  namespace: string,
  currentTranslations: RootTranslationObject,
  newTranslationsObject: RootTranslationObject,
  analyzeExistingTranslations = false
) => {
  if (analyzeExistingTranslations) {
    await analyzeTranslations(
      namespace,
      currentTranslations,
      newTranslationsObject
    );
  }

  return deepMerge<RootTranslationObject>(
    currentTranslations,
    newTranslationsObject
  );
};
