import {
  Authentication,
  DevelopmentStage,
  ProductionStage,
  StagingStage,
} from "@apetito/sdk";

import env from "../utils/env";
import {
  IAuthentication,
  IAuthenticationOptions,
} from "@apetito/sdk/dist/models/Authentication";
import { getCurrentURIFullPath, navigateToUrl } from "../utils/navigation";

const stage = env.PORTAL_SDK_COMMON_ENVIRONMENT;

let stageObject: IAuthenticationOptions;
if (stage == "Development") stageObject = DevelopmentStage;
else if (stage == "Staging") stageObject = StagingStage;
else stageObject = ProductionStage;

export class CoreAuthentication extends Authentication {
  private _LS_REDIRECT_URI_KEY = "_meinapetito__post-login--redirect";

  constructor(props: IAuthenticationOptions) {
    super(props);
  }

  getToken() {
    return super
      .getToken()
      .then((data) => {
        /* If we have a redirect URI saved in the LS and we succeeded in fetching the user data, let's remove it
         * and redirect the user to the page he was on before, when an exception occurred (probably expired refresh token). */
        const redirectUri = localStorage.getItem(this._LS_REDIRECT_URI_KEY);
        localStorage.removeItem(this._LS_REDIRECT_URI_KEY);

        if (redirectUri) {
          setTimeout(() => {
            /* We want to use the `window.history.pushState` here to prevent the reload of the app to not make another call to getCurrentUserInfo */
            navigateToUrl(redirectUri);
          }, 0);
        }

        return data;
      })
      .catch((error: Error) => {
        if (
          ["redirect_uri_mismatch", "monitor_window_timeout"].includes(
            // @ts-ignore
            error?.errorCode
          )
        ) {
          /* Users' refresh token probably got expired - we need a regular login process and for that,
           * we throw the user to the root path, MSAL will take care of the rest.
           * Before that, let's save the path the user is currently on, so the next time user data is fetched successfully,
           * we'll redirect him to the page he wanted to end up in the first place. */
          localStorage.setItem(
            this._LS_REDIRECT_URI_KEY,
            getCurrentURIFullPath()
          );
          window.location.href = "/";
        }

        throw error;
      });
  }
}

const publicPaths = ["/signin", "/privacy-policy"];

const authentication: IAuthentication = new CoreAuthentication({
  ...stageObject,
  publicPaths,
});

export { authentication, publicPaths };
