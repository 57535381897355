import { getCurrentInstance } from "vue";
import { SignalRHub } from "../../hubs";
import { HubReturnType, useSignalR } from "../../signalr.client";
import { registerEventBusHandler } from "../../utils/eventBus";
import { MenuSelectionHubEvents } from "./signalR.events";

export type MenuSelectionHubReturnType<T> = HubReturnType<T> & {
  reconnect(customerNumber: string): void;
};

export const useMenuSelectionHub = (
  customerNumber: string,
  connectionHubEndpoint: string,
  context: ReturnType<typeof getCurrentInstance>
): MenuSelectionHubReturnType<typeof MenuSelectionHubEvents> => {
  const signalRConnectionUrl = new URL(
    `${connectionHubEndpoint}/hubs/${SignalRHub.MENU_SELECTION_ORDER}`
  );

  const events = MenuSelectionHubEvents;

  let signalR: ReturnType<typeof useSignalR> | null = null;

  initSignalR(customerNumber);
  registerForAllEvents();

  function initSignalR(customerNumber: string) {
    signalRConnectionUrl.searchParams.set("customerNumber", customerNumber);

    signalR = useSignalR(
      decodeURIComponent(signalRConnectionUrl.toString()),
      context
    );
  }

  function unregisterFromAllEvents() {
    for (const eventName of Object.values(events)) {
      signalR.offAll(eventName);
    }
  }

  function registerForAllEvents() {
    for (const eventName of Object.values(events)) {
      registerEventBusHandler<typeof MenuSelectionHubEvents>(
        signalR,
        eventName
      );
    }
  }

  function reconnect(customerNumber: string) {
    if (signalR.connection.value.connectionId) {
      unregisterFromAllEvents();
      initSignalR(customerNumber);
      registerForAllEvents();
    }
  }

  return {
    signalR,
    events,
    reconnect,
  };
};
